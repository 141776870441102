/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
import { Dropdown } from 'primeng/dropdown';
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// Solution for dropdown filter space key
Dropdown.prototype.onKeydown = function (event, search) {
    if (this.readonly || !this.optionsToDisplay || this.optionsToDisplay.length === null) {
        return;
    }
    switch (event.which) {
        // down
        case 40:
            if (!this.overlayVisible && event.altKey) {
                this.show();
            } else {
                if (this.group) {
                    const selectedItemIndex = this.selectedOption ?
                        this.findOptionGroupIndex(this.getOptionValue(this.selectedOption), this.optionsToDisplay) : -1;
                    if (selectedItemIndex !== -1) {
                        const nextItemIndex = selectedItemIndex.itemIndex + 1;
                        if (nextItemIndex < (this.getOptionGroupChildren(this.optionsToDisplay[selectedItemIndex.groupIndex]).length)) {
                            this.selectItem(
                                event,
                                this.getOptionGroupChildren(this.optionsToDisplay[selectedItemIndex.groupIndex])[nextItemIndex]
                            );
                            this.selectedOptionUpdated = true;
                        } else if (this.optionsToDisplay[selectedItemIndex.groupIndex + 1]) {
                            this.selectItem(event, this.getOptionGroupChildren(this.optionsToDisplay[selectedItemIndex.groupIndex + 1])[0]);
                            this.selectedOptionUpdated = true;
                        }
                    } else {
                        if (this.optionsToDisplay && this.optionsToDisplay.length > 0) {
                            this.selectItem(event, this.getOptionGroupChildren(this.optionsToDisplay[0])[0]);
                        }
                    }
                } else {
                    const selectedItemIndex = this.selectedOption ?
                        this.findOptionIndex(this.getOptionValue(this.selectedOption), this.optionsToDisplay) : -1;
                    const nextEnabledOption = this.findNextEnabledOption(selectedItemIndex);
                    if (nextEnabledOption) {
                        this.selectItem(event, nextEnabledOption);
                        this.selectedOptionUpdated = true;
                    }
                }
            }
            event.preventDefault();
            break;
        // up
        case 38:
            if (this.group) {
                const selectedItemIndex = this.selectedOption ?
                    this.findOptionGroupIndex(this.getOptionValue(this.selectedOption), this.optionsToDisplay) : -1;
                if (selectedItemIndex !== -1) {
                    const prevItemIndex = selectedItemIndex.itemIndex - 1;
                    if (prevItemIndex >= 0) {
                        this.selectItem(
                            event,
                            this.getOptionGroupChildren(this.optionsToDisplay[selectedItemIndex.groupIndex])[prevItemIndex]
                        );
                        this.selectedOptionUpdated = true;
                    } else if (prevItemIndex < 0) {
                        const prevGroup = this.optionsToDisplay[selectedItemIndex.groupIndex - 1];
                        if (prevGroup) {
                            this.selectItem(
                                event,
                                this.getOptionGroupChildren(prevGroup)[this.getOptionGroupChildren(prevGroup).length - 1]
                            );
                            this.selectedOptionUpdated = true;
                        }
                    }
                }
            } else {
                const selectedItemIndex = this.selectedOption ?
                    this.findOptionIndex(this.getOptionValue(this.selectedOption), this.optionsToDisplay) : -1;
                const prevEnabledOption = this.findPrevEnabledOption(selectedItemIndex);
                if (prevEnabledOption) {
                    this.selectItem(event, prevEnabledOption);
                    this.selectedOptionUpdated = true;
                }
            }
            event.preventDefault();
            break;
        // space
        case 32:
            if (!this.overlayVisible) {
                this.show();
                event.preventDefault();
            } else {
                if (!this.filter) {
                    this.hide();
                    event.preventDefault();
                }
            }

            break;
        // enter
        case 13:
            if (this.overlayVisible && (!this.filter || (this.optionsToDisplay && this.optionsToDisplay.length > 0))) {
                this.hide();
            } else if (!this.overlayVisible) {
                this.show();
            }
            event.preventDefault();
            break;
        // escape and tab
        case 27:
        case 9:
            this.hide();
            break;
        // search item based on keyboard input
        default:
            if (search && !event.metaKey) {
                this.search(event);
            }
            break;
    }
};
